/*
* BASE DEPENDENCIES
*/

@import "abstracts/mixins", "abstracts/functions", "abstracts/vars";

/*
* LAYOUT
*/

@import "layout/masonry";

@import "components/breadcrumb-nav", "components/gallery",
	"components/swipeview";

main {
	background: white url(/assets/images/bg.jpg) repeat center;
}

article.page {
	max-width: 830px;
	margin-left: auto;
	margin-right: auto;
}

.page-module {
	margin-bottom: 3em;
	font-size: rem(20px);
}

.cta {
	text-align: center;
}

.link-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 1.1rem;
	background-color: #3b5997;
	color: #fff;
	border-radius: 0.4rem;
	font-size: rem(18px);
	max-width: 18rem;
	min-width: 15rem;
	text-align: center;
	transition: all 0.238s;
	background-color: #3b5998;

	&::before {
		content: encode-svg(
			'<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 20 20" width="20" height="20"><g transform="matrix(0.8333333333333334,0,0,0.8333333333333334,0,0)"><path d="M18.13,7.75H13.87V5.84a1,1,0,0,1,1-1.1h3v-4H13.54c-3.93,0-4.67,3-4.67,4.86V7.75h-3v4h3v11.5h5V11.75h3.85Z" fill="#fff"></path></g></svg>'
		);
		margin-right: 0.6rem;
		display: inline-block;
		width: 20px;
		height: 20px;
	}

	@include link(&) {
		color: #fff;
		text-decoration: none;
	}

	@include link-over(&) {
		background-color: #2d4373;
	}
}
