.breadcrumb-nav {
	&__list {
		margin: 0 0 3em;
		padding: 0;
		display: inline-flex;
		flex-wrap: wrap;
		list-style-type: none;
	}

	&__item {
		&:not(:last-child)::after {
			content: "\>";
			margin: 0 0.5em;
		}
	}

	&__link {
		display: inline-block;

		@include link(&) {
			text-decoration: none;
		}

		@include link-over(&) {
			color: $color-brand;
		}

		&[aria-current] {
			pointer-events: none;
			text-decoration: none;
			font-weight: bold;
		}
	}
}
