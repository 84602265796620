
$spacing: $grid-spacing*2;
$spacing-fixed: $grid-spacing-fixed*2;

.masonry {
	list-style-type: none;
	padding: 0;
	margin: 0;

	&__item {
		-webkit-column-break-inside: avoid;
		page-break-inside: avoid;
		break-inside: avoid;
		position: relative;
		margin-bottom: 0;
		display: inline-flex;
		flex-direction: column;
	}

	&__item {
		margin-bottom: $spacing;
	}

	@include min(450px) {
		column-count: 2;
		column-gap: $spacing;
	}

	@include min(1024px) {
		column-count: 3;
		column-gap: $spacing*.75;

		&__item {
			margin-bottom: $spacing*.75;
		}
	}

	@include min(#{$max-width}) {
		column-gap: $spacing-fixed*.75;

		&__item {
			margin-bottom: $spacing-fixed*.75;
		}
	}
}
