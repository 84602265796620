/*
* BASE DEPENDENCIES
*/
/*****************************************
* Blackwell - MIXINS
*****************************************/
/*****************************************
* blackwell - FUNCTIONS
*****************************************/
/*==============================
	FONTS
==============================*/
/*==============================
	COLORS
==============================*/
/*==============================
	SIZES
==============================*/
/* COMPONENT VARIABLES */
/*==============================
	FORMS
==============================*/
/*==============================
	BUTTONS
==============================*/
/*
* LAYOUT
*/
.masonry {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.masonry__item {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  position: relative;
  margin-bottom: 0;
  display: inline-flex;
  flex-direction: column;
}

.masonry__item {
  margin-bottom: 5vmin;
}

@media screen and (min-width: 450px) {
  .masonry {
    column-count: 2;
    column-gap: 5vmin;
  }
}

@media screen and (min-width: 1024px) {
  .masonry {
    column-count: 3;
    column-gap: 3.75vmin;
  }
  .masonry__item {
    margin-bottom: 3.75vmin;
  }
}

@media screen and (min-width: 1600px) {
  .masonry {
    column-gap: 63px;
  }
  .masonry__item {
    margin-bottom: 63px;
  }
}

.breadcrumb-nav__list {
  margin: 0 0 3em;
  padding: 0;
  display: inline-flex;
  flex-wrap: wrap;
  list-style-type: none;
}

.breadcrumb-nav__item:not(:last-child)::after {
  content: "\>";
  margin: 0 0.5em;
}

.breadcrumb-nav__link {
  display: inline-block;
}

.breadcrumb-nav__link, .breadcrumb-nav__link:link, .breadcrumb-nav__link:visited {
  text-decoration: none;
}

.breadcrumb-nav__link:hover, .breadcrumb-nav__link:focus, .breadcrumb-nav__link:active {
  color: #30acac;
}

.breadcrumb-nav__link[aria-current] {
  pointer-events: none;
  text-decoration: none;
  font-weight: bold;
}

.gallery__item {
  display: flex;
  flex-direction: column;
  position: relative;
}

.gallery__link {
  line-height: 0;
  display: block;
}

.gallery__image {
  width: 100%;
  border-radius: 8px;
}

@keyframes swipeview-reveal {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes swipeview-hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.swipeview {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30999;
  background: rgba(56, 52, 51, 0.85);
  backdrop-filter: blur(5px);
  scroll-behavior: auto;
  opacity: 0;
}

.swipeview:not([data-is-open]) {
  visibility: hidden;
}

.swipeview[data-is-open="true"] {
  display: block;
  animation: swipeview-reveal 0.25s ease-out forwards;
}

.swipeview[data-is-open="false"] {
  animation: swipeview-hide 0.25s ease-out forwards;
}

.swipeview__list-wrapper {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: auto;
}

.swipeview__list {
  touch-action: manipulation;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  --edge-transition: none;
  --edge-transform: translateX(0%);
}

.swipeview__item {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transition: var(--edge-transition);
  transform: var(--edge-transform);
}

.swipeview__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  max-height: 100%;
}

.swipeview__button {
  position: fixed;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 50px;
  height: 50px;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in;
  background-color: rgba(56, 52, 51, 0.75);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: none;
  background-size: 65%;
}

.swipeview__button:hover, .swipeview__button:focus {
  outline: 0;
  background-color: #30acac;
}

@media screen and (min-width: 800px) {
  .swipeview__button {
    width: 70px;
    height: 70px;
  }
}

.swipeview__button[disabled] {
  visibility: hidden;
}

.swipeview__button.-exit-button {
  right: 0;
  top: 0;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E");
}

.swipeview__button.-previous-button, .swipeview__button.-next-button {
  transform: translateY(-50%);
}

.swipeview__button.-previous-button {
  left: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M8.502 20c-.003-.259.092-.519.289-.716l19.008-19.007c.389-.389 1.02-.389 1.408 0s.389 1.019 0 1.407l-18.315 18.316 18.315 18.315c.389.389.389 1.019 0 1.407s-1.02.389-1.408 0l-19.008-19.006c-.197-.197-.292-.457-.289-.716z'/%3E%3C/svg%3E");
}

.swipeview__button.-next-button {
  right: 0;
  top: 50%;
  background-image: url("data:image/svg+xml;utf8,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' width='40' height='40'%3E%3Cpath fill='%23f6f2ef' d='M31.209 20.716l-19.008 19.007c-.389.389-1.019.389-1.407 0s-.389-1.019 0-1.407l18.314-18.316-18.315-18.315c-.389-.389-.389-1.019 0-1.407s1.019-.389 1.407 0l19.008 19.007c.197.197.292.457.289.716.004.258-.091.518-.288.715z'/%3E%3C/svg%3E");
}

main {
  background: white url(/assets/images/bg.jpg) repeat center;
}

article.page {
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
}

.page-module {
  margin-bottom: 3em;
  font-size: 1.25rem;
}

.cta {
  text-align: center;
}

.link-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem;
  background-color: #3b5997;
  color: #fff;
  border-radius: 0.4rem;
  font-size: 1.125rem;
  max-width: 18rem;
  min-width: 15rem;
  text-align: center;
  transition: all 0.238s;
  background-color: #3b5998;
}

.link-button::before {
  content: url("data:image/svg+xml;utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 20 20' width='20' height='20'%3E%3Cg transform='matrix(0.8333333333333334,0,0,0.8333333333333334,0,0)'%3E%3Cpath d='M18.13,7.75H13.87V5.84a1,1,0,0,1,1-1.1h3v-4H13.54c-3.93,0-4.67,3-4.67,4.86V7.75h-3v4h3v11.5h5V11.75h3.85Z' fill='%23fff'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  margin-right: 0.6rem;
  display: inline-block;
  width: 20px;
  height: 20px;
}

.link-button, .link-button:link, .link-button:visited {
  color: #fff;
  text-decoration: none;
}

.link-button:hover, .link-button:focus, .link-button:active {
  background-color: #2d4373;
}
